import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import ButtonsNormal from '../DirectoryCard/ButtonsNormal';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import MailIcon from '@material-ui/icons/Mail';

import MessageIcon from '@material-ui/icons/Message';
import Link from '@material-ui/core/Link';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    marginBottom: 0,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  action: {
    margin: theme.spacing(1),
    padding: 0,
  },
  avatar: {
    marginTop: -11,
    padding: 0,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  card: {
    marginTop: 5,
    marginBottom: 5,
    height: 50,
    backgroundColor: "#E9F1F7",
  },
  titre: {
    marginTop: -14,
    marginBottom: -7,
  },
  smallAvatar: {
    color: theme.palette.primary.main,
    backgroundColor: "#ffffff",
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function formatte(tel) {
  if (tel.length !== 10) {
    return tel
  }
  return tel.substring(0, 2) + ' ' + tel.substring(2, 4) + ' ' + tel.substring(4, 6) + ' ' + tel.substring(6, 8) + ' ' + tel.substring(8, 10)
}


export default function MyCard(props) {
  const classes = useStyles();
  const { data } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const phoneText1 = (
    <Typography>
      {formatte(data['Tel 1'])}
    </Typography>
  )
  const phoneLink1 = (
    <Link href={'tel:' + data['Tel 1']} >
      <Avatar variant="rounded" className={classes.smallAvatar}>
        <PhoneIcon />
      </ Avatar>
    </Link>
  )
  const smsLink1 = (
    <Link href={'sms:' + data['Tel 1']} >
      <Avatar variant="rounded" className={classes.smallAvatar}>
        <MessageIcon />
      </ Avatar>
    </Link>
  )
  const emailText1 = (
    <Typography>
      {data['Email 1']}
    </Typography>
  )
  const emailLink1 = (
    <Link href={'mailto:' + data['Email 1']}>
      <Avatar variant="rounded" className={classes.smallAvatar}>
        <MailIcon />
      </ Avatar>
    </Link>
  )

  const Contact1 = (
    <Fragment>
      <Box p={.5} />
      <Typography style={{ fontWeight: 600 }}>
        {data['Contact 1']}
      </Typography>
      <Paper className={classes.paper}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>{phoneText1}</Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>{phoneLink1}</Grid>
              <Grid item>{smsLink1}</Grid>
            </Grid>
          </Grid>

        </Grid>
        <Paper className={classes.paper}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={8}>{emailText1}</Grid>
            <Grid item>{emailLink1}</Grid>
          </Grid>
        </Paper>
      </Paper>
    </Fragment>
  )

  //Au cas où il manque des informations
  let Contact2

  const phoneText2 = (
    <Typography>
      {formatte(data['Tel 2'])}
    </Typography>
  )
  const phoneLink2 = (
    <Link href={'tel:' + data['Tel 2']} >
      <Avatar variant="rounded" className={classes.smallAvatar}>
        <PhoneIcon />
      </ Avatar>
    </Link>
  )
  const smsLink2 = (
    <Link href={'sms:' + data['Tel 2']} >
      <Avatar variant="rounded" className={classes.smallAvatar}>
        <MessageIcon />
      </ Avatar>
    </Link>
  )
  const emailText2 = (
    <Typography>
      {data['Email 2']}
    </Typography>
  )
  const emailLink2 = (
    <Link href={'mailto:' + data['Email 2']}>
      <Avatar variant="rounded" className={classes.smallAvatar}>
        <MailIcon />
      </ Avatar>
    </Link>
  )

  if (data['Contact 2'] !== '' || data['Email 2'] !== '' || data['Tel 2'] !== '') {
    Contact2 = (
      <Fragment>
        <Box p={1.5} />
        <Typography style={{ fontWeight: 600 }}>
          {data['Contact 2']}
        </Typography>
        <Box p={.5} />
        <Paper className={classes.paper}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={8}>{phoneText2}</Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>{phoneLink2}</Grid>
                <Grid item>{smsLink2}</Grid>
              </Grid>
            </Grid>

          </Grid>
          <Paper className={classes.paper}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={8}>{emailText2}</Grid>
              <Grid item>{emailLink2}</Grid>
            </Grid>
          </Paper>
        </Paper>
      </Fragment>
    )
  }

  return (
    <Container maxWidth="xs" >

      <Card className={classes.card} elevation={4} onClick={handleClickOpen}>
        <CardActionArea >
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Avatar className={classes.avatar}>
                  {data['Équipe'].substring(0, 1).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                </ Avatar>
              </Grid>
              <Grid item>
                <Typography className={classes.titre} variant="subtitle1">
                  {data['Équipe']}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Poule {data['Poule']}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>


      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {data['Équipe']}
            </Typography>
          </Toolbar>
        </AppBar>

        <Box p={1.5} />
        <Typography variant="h6">
          Adresse
        </Typography>
        <Typography>
          {data['Adresse']}.
          <br />
          Cliquez <a href={data['Lien maps']}>ici</a> pour ouvrir dans google maps.
        </Typography>

        <Box p={1.5} />
        <Typography variant="h6">
          Créneau
        </Typography>
        <Typography>
          {data['Horaire']}
        </Typography>

        <Box p={1.5} />

        <Typography variant="h6">
          Contact(s)
        </Typography>
        {Contact1}
        {Contact2}
        <Box p={1.5} />
        <ButtonsNormal handleClickAccept={handleClose} buttonName="Fermer" />

      </Dialog>
    </Container >
  );
}