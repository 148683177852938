import React from "react"
import Fab from '@material-ui/core/Fab';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';
import 'date-fns';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import firebase from '../Firebase/firebase'

import { Badge } from "@material-ui/core";
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import { styled } from '@mui/material/styles';

import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(1),
    backgroundColor: "#8ad5fb"
  },
  avatarPlus: {
    backgroundColor: "#8ad5fb",
    color: 'black'
  },
}));



const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  marginLeft: 8,
  color: "#8ad5fb",
  backgroundColor: "white"
}));




export default function AddButton(props) {
  const classes = useStyles()

  const { branchName, currentDateId, userId } = props

  //Dialog d'info : est-il ouvert ?
  const [open, setOpen] = React.useState(false)

  return (
    <div>
      <Fab
        aria-label="add"
        size='medium'
        className={classes.fab}
        onClick={() => setOpen(true)}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <SmallAvatar alt="Covid">
              <PersonIcon />
            </SmallAvatar>
          }
        >
          <Avatar className={classes.avatarPlus} >
            <AddIcon />
          </ Avatar>
        </Badge>
      </Fab>
      {PopUpDialog(open, setOpen, branchName, currentDateId, userId)}
    </div>
  )
}



function PopUpDialog(open, setOpen, branchName, currentDateId, userId) {



  const [nom, setNom] = React.useState('')
  const [prenom, setPrenom] = React.useState('')
  const [pseudo, setPseudo] = React.useState('')

  //State to enable/disable saving button
  const [disableSave, setDisableSave] = React.useState(true)


  function handleChangeNom(event) {
    setNom(event.target.value)
    if ((event.target.value !== '') || (prenom !== '') || (pseudo !== '')) {
      setDisableSave(false)
    }
    else {
      setDisableSave(true)
    }
  }

  function handleChangePrenom(event) {
    setPrenom(event.target.value)
    if ((nom !== '') || (event.target.value !== '') || (pseudo !== '')) {
      setDisableSave(false)
    }
    else {
      setDisableSave(true)
    }
  }

  function handleChangePseudo(event) {
    setPseudo(event.target.value)
    if ((nom !== '') || (prenom !== '') || (event.target.value !== '')) {
      setDisableSave(false)
    }
    else {
      setDisableSave(true)
    }
  }


  function closeDialog() {
    console.log('closing Dialog')
    setPrenom('')
    setNom('')
    setPseudo('')
    setDisableSave(true)
    setOpen(false)
  }

  function handleCancel() {
    console.log('Aborting save')
    closeDialog()
  }

  function handleOk() {
    console.log('Saving and closing')
    var myRef = firebase.database().ref('/evenements/' + branchName + '/' + currentDateId + '/invites')
    var newStuff = myRef.push()
    newStuff.set({ nom, prenom, pseudo, pushedBy: userId })
    closeDialog()
  }




  var inputFields = (
    <div>
      <TextField id="standard-basic"
        label="Nom"
        multiline
        value={nom}
        onChange={handleChangeNom}
        fullWidth
      />
      <TextField id="standard-basic"
        label="Prénom"
        multiline
        value={prenom}
        onChange={handleChangePrenom}
        fullWidth
      />
      <TextField id="standard-basic"
        label="Pseudo"
        multiline
        value={pseudo}
        onChange={handleChangePseudo}
        fullWidth
      />
    </div>
  )

  return (
    <div>
      <Dialog
        onClose={(event, reason) => { }}
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="Ajouter un participant"
        open={open}
        fullWidth
      >
        <DialogContent>

          <Typography variant='h6'>
            Ajouter un participant
          </Typography>
          <Box p={2} />
          {inputFields}
          <Box p={1} />
        </DialogContent>
        <DialogActions>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button onClick={handleCancel} color="primary">
                Annuler
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleOk} color="primary" disabled={disableSave}>
                Sauvegarder
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}