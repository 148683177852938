import React, { useContext } from 'react'
import MyCard from '../MyCard/MyCard'
import Typography from '@material-ui/core/Typography'

import FirebaseContext from '../Firebase/FirebaseContext'
import firebase from '../Firebase/firebase';

import { extractPresenceEntrainements, extractPresence, multipleEvents, extractPresencePot, findLocationAndTime, mySort, nombreSelections2 } from '../UtilityScripts/TreeParsing'
import LoadingDiv from '../LoadingDiv/LoadingDiv';
import { isAdmin } from '../UtilityScripts/FindStuff'


function isUserCoach(user, loadingW, treeW) {
  if (loadingW || !treeW) {
    return false
  }
  else {
    return (treeW[user['uid']]['coach'])
  }
}

function isUserAdminCovid(user, loadingW, treeW) {
  if (loadingW || !treeW) {
    return false
  }
  else {
    return (treeW[user['uid']]['adminCovid'])
  }
}

// function isUserMiniAdmin(user, loadingW, treeW) {
//   if (loadingW || !treeW) {
//     return false
//   }
//   else {
//     return (treeW[user['uid']]['miniAdmin'])
//   }
// }


function getOptions(trainingOrMatch, admin, adminCovid, coach, currentDateId, loadings, trees) {
  //Suivant la date, on peut ou non modifier les options
  //Avant l'événement : c'est possible
  //Après, c'est impossible SAUF si on est coach/admin/adminCovid

  //Liste des options de présence
  let options
  var optionsA = ['Présent(e)', 'Provisoire', 'Absent(e)']
  var optionsB = ['Oui', 'Non', 'Si besoin']

  if (trainingOrMatch === 'entrainements' || trainingOrMatch === 'pots' || trainingOrMatch === 'beach') {
    options = optionsA
  }
  else {
    options = optionsB
  }

  //Get local date and hour
  const currentDate = new Date()

  //Get date of current event :
  const { date } = findLocationAndTime(trees['treeE'], loadings['loadingE'], trainingOrMatch, currentDateId)

  //If currentDate is before date 
  let futureDate //pour un utilisateur lambda, indique s'il peut encore modifier sa présence
  var superUser = false//indique si un utilisateur peut modifier toutes les présences (pas que les siennes)

  if (date && date.getTime() > currentDate.getTime()) {
    futureDate = true
    if (admin || (coach && trainingOrMatch !== 'entrainements')) {
      superUser = true
    }
  }
  else {
    futureDate = false
    //If date is after dateEvent with a margin of 7 hours
    if (date && date.getTime() + 7 * 60 * 60 * 1000 > currentDate.getTime()) {
      if ((adminCovid && trainingOrMatch === 'entrainements') || admin || coach) {
        superUser = true
      }
    }
    else if (admin || (coach && trainingOrMatch !== 'entrainements')) {
      superUser = true
    }
  }
  return { options, futureDate, superUser }
}


function updateListSportifs(dateId, userId, trees, loadings, errors, trainingOrMatch, triPresence, affichagePseudos) {
  //Fonction qui met à jour la liste des sportifs
  if (loadings['loadingW'] || loadings['loadingU']) return []

  //Pour que ça soit lisible
  const { treeE, treeU, treeW, treeP } = trees;
  const { loadingE, loadingU, loadingW, loadingP } = loadings;
  const { errorP } = errors;

  let sportifs
  if (trainingOrMatch === 'pots') {
    ({ sportifs } = extractPresencePot(treeE, loadingE, treeW, loadingW, treeU, loadingU, dateId))
  }
  else if (trainingOrMatch === 'entrainements') {
    ({ sportifs } = extractPresenceEntrainements(treeE, loadingE, treeW, loadingW, treeU, loadingU, trainingOrMatch, dateId))
  }
  else {
    ({ sportifs } = extractPresence(treeE, loadingE, treeW, loadingW, treeU, loadingU, trainingOrMatch, dateId))
  }

  //Tri des sportifs
  //Show me first ?
  let meFirst
  if (!loadingP && !errorP) {
    meFirst = treeP['readWrite']['meFirst']
  }
  sportifs.sort(mySort(triPresence, meFirst, userId, affichagePseudos))

  return sportifs
}



export default function ListeSportifs(props) {

  const { trees, loadings, errors,
    user,
    triPresence,
    affichagePseudos,
  } = useContext(FirebaseContext)

  const { currentDateId, trainingOrMatch } = props;

  //Get userId to highlight current user
  const userId = user['uid']

  //Liste des sportifs
  var sportifs = updateListSportifs(currentDateId, userId, trees, loadings, errors, trainingOrMatch, triPresence, affichagePseudos)


  //Is current user admin ?
  const admin = isAdmin(trees['treeW'], loadings['loadingW'], errors['errorW'], userId)

  //Is current user miniAdmin?
  // const miniAdmin = isUserMiniAdmin(trees['treeW'], loadings['loadingW'], errors['errorW'], userId)

  //Is current user coach ?
  const coach = isUserCoach(user, loadings['loadingW'], trees['treeW'])

  //Can coach make its selection ?
  const selection = (coach && (trainingOrMatch === 'equipe1' || trainingOrMatch === 'equipe2'))

  //Is user a covid admin ?
  let adminCovid
  if (trainingOrMatch === 'entrainements') {
    adminCovid = isUserAdminCovid(user, loadings['loadingW'], trees['treeW'])
  }

  //If current user can make a selection, add stuff to sportifs list
  if (selection) {
    sportifs = nombreSelections2(trees['treeE'], loadings['loadingE'], trees['treeW'], loadings['loadingW'], currentDateId, sportifs, trainingOrMatch)
  }


  //Options de présence et droits d'écriture
  const { options, futureDate, superUser } = getOptions(trainingOrMatch, admin, adminCovid, coach, currentDateId, loadings, trees)

  function handleUpdateSportif(sportif) {
    //Fonction qui met à jour un sportif après modification éventuelle de sa présence
    var myRef = firebase.database().ref("evenements/").child(trainingOrMatch).child(currentDateId).child('inscrits').child(sportif['uid']).child('present')
    myRef.set(sportif['present'])
  }

  function handleDeleteExterieur(sportif, del) {
    //Fonction qui supprime un extérieur du listing
    //si del = true, supprime l'extérieur
    if (del) {
      var myRef = firebase.database().ref("evenements/").child(trainingOrMatch).child(currentDateId).child('exterieurs').child(sportif['uid'])
      myRef.remove()
    }
  }

  function handleDeleteInvite(sportif, del) {
    //Fonction qui supprime un invité du listing
    //si del = true, supprime l'extérieur
    if (del) {
      var myRef = firebase.database().ref("evenements/").child(trainingOrMatch).child(currentDateId).child('invites').child(sportif['uid'])
      myRef.remove()
    }
  }


  function handleUpdatePot(sportif) {
    var myRef = firebase.database().ref("evenements/").child(trainingOrMatch).child(currentDateId).child('inscrits').child(sportif['uid'])
    myRef.set({
      present: sportif['present'],
      boissons: sportif['boissons'],
      sucre: sportif['sucre'],
      sale: sportif['sale'],
      autres: sportif['autres'],
    })
  }

  //Sauvegarde de la sélection
  function handleSaveCoach(sportif) {
    //On regarde s'il y a un autre événement à cette date pour y sauvegarder
    //aussi la séléction.
    const { mult, events } = multipleEvents(trees['treeE'], loadings['loadingE'], errors['errorE'], trainingOrMatch, currentDateId)
    let myRef
    if (mult) {
      console.log("Le match a lieu en même temps qu'un autre événement. Sauvegarde de la séléction dans toutes les branches correspondantes.")
      for (const ev of events) {
        myRef = firebase.database().ref("evenements/").child(ev['branchName']).child(ev['dateId']).child('selections').child(trainingOrMatch).child(sportif['uid'])
        myRef.set(sportif['selectionne'])
      }
    }
    //
    myRef = firebase.database().ref("evenements/").child(trainingOrMatch).child(currentDateId).child('selection').child(sportif['uid'])
    myRef.set(sportif['selectionne'])
  }


  //Affichage de la liste si elle existe
  let listeSportifs
  if (currentDateId === 'fake date id') {
    listeSportifs = <LoadingDiv />
  }
  else if (sportifs.length === 0) {
    listeSportifs = (
      <Typography align='justify'>
        Il y a eu une erreur quelque part.
        Votre compte a-t-il été bien validé ? Si ce n'est pas le cas,
        pensez à renseigner vos noms/prénoms dans l'onglet paramètres.

        Sinon, peut-être que l'événement auquel vous tentez d'accéder a été
        supprimé.
      </Typography>
    )
  }
  else {
    //Is it current user || a user that trusts current user
    for (const sportif of sportifs) {
      sportif['updateable'] = false
      if (sportif['uid'] === userId || sportif['pushedBy'] === userId) {
        sportif['updateable'] = true
      }
      else {
        if (!sportif['trustedUsers']) {
          continue
        }
        else if (sportif['trustedUsers'][userId]) {
          sportif['updateable'] = true
        }
      }
    }

    listeSportifs = (
      sportifs.map(sportif => <MyCard key={sportif['uid']}
        sportif={sportif}
        options={options}
        futureDate={futureDate}
        superUser={superUser}
        updateable={sportif['updateable']}
        affichagePseudos={affichagePseudos}
        selection={selection}
        handleUpdateSportif={handleUpdateSportif}
        handleSaveCoach={handleSaveCoach}
        handleUpdatePot={handleUpdatePot}
        handleDeleteExterieur={handleDeleteExterieur}
        handleDeleteInvite={handleDeleteInvite}
        trainingOrMatch={trainingOrMatch}
        userId={userId}
      />)
    )
  }

  return (
    <div>
      {listeSportifs}
    </div>
  )
}