import React, { useEffect, useState } from "react"
import Fab from '@material-ui/core/Fab';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';
import 'date-fns';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import firebase from '../Firebase/firebase'

import { Badge } from "@material-ui/core";
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import { styled } from '@mui/material/styles';
import AlertConfirm from '../Administration/AlertConfirm.jsx';

import { makeStyles } from '@material-ui/core/styles';


import {
  BrowserMultiFormatReader,
  NotFoundException
} from '@zxing/library';
import Paper from '@mui/material/Paper';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { FiCameraOff } from 'react-icons/fi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: 'center',
}));

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: '#26ce59',
  },
  avatarPlus: {
    backgroundColor: '#4fe17b',
    color: 'black'
  },
}));



const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  marginLeft: 8,
  color: "#26ce59",
  backgroundColor: "white"
}));




export default function AddButton(props) {
  const classes = useStyles()

  const { branchName, currentDateId } = props

  //Dialog d'info : est-il ouvert ?
  const [open, setOpen] = React.useState(false)

  return (
    <div>
      <Fab
        aria-label="add"
        size='medium'
        className={classes.fab}
        onClick={() => setOpen(true)}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <SmallAvatar alt="Covid">
              <PersonIcon />
            </SmallAvatar>
          }
        >
          <Avatar className={classes.avatarPlus} >
            <AddIcon />
          </ Avatar>
        </Badge>
      </Fab>
      {PopUpDialog(open, setOpen, branchName, currentDateId)}
    </div>
  )
}



function PopUpDialog(open, setOpen, branchName, currentDateId) {

  const codeReader = new BrowserMultiFormatReader()

  const [showCam, setShowCam] = useState(false);

  useEffect(() => {
    if (showCam) {
      codeReader.listVideoInputDevices()
        .then((videoInputDevices) => {
          console.log('ZXing code reader found devices', videoInputDevices)
          if (!videoInputDevices.length) {
            console.log('ZXing code reader found no devices')
          }
          else {
            console.log(videoInputDevices)
            setSelectedDeviceId(videoInputDevices[0].deviceId)
          }
        });
    }
    else {
      codeReader.reset()
    }
  }, [codeReader, showCam])


  useEffect(
    () => {
      if (showCam) {
        if (selectedDeviceId && showCam) {
          console.log("decoding")
          codeReader.decodeFromVideoDevice(undefined, 'video', (result, err) => {
            if (result) {
              setShowCam(false)
              codeReader.reset()
              handleResult(result.text)
            }
            if (err && !(err instanceof NotFoundException)) {
              console.error(err)
              handleError(err)
            }
          })
          console.log(`Started continous decode from camera with id ${selectedDeviceId}`)
        }
      }
      else {
        codeReader.reset()
      }
    }
    // []
  );


  const [nom, setNom] = React.useState('')
  const [prenom, setPrenom] = React.useState('')
  const [telephone, setTelephone] = React.useState('')
  const [email, setEmail] = React.useState('')

  //State for the alert dialog
  const [alertMessage, setAlertMessage] = React.useState('')

  //State to enable/disable saving button
  const [disableSave, setDisableSave] = React.useState(true)

  //State to pass to Scanner4.jsx
  const [resultScan, setResultScan] = React.useState('Attente')

  //To know whether a pass is valid or not
  const [passValide, setPassValide] = React.useState(false)

  //State to open an alert dialog when deleting an event
  const [openAlert, setOpenAlert] = React.useState(false)

  //Sanipasse key
  const key = 'de59ec66-d9a5-4932-9d3b-55ad2034973c'

  //Scanner stuff
  const [selectedDeviceId, setSelectedDeviceId] = useState('');





  function handleChangeNom(event) {
    setShowCam(false)
    codeReader.reset()
    setNom(event.target.value)
    if ((event.target.value !== '') && (prenom !== '')) {
      setDisableSave(false)
    }
    else {
      setDisableSave(true)
    }
  }

  function handleChangePrenom(event) {
    setShowCam(false)
    codeReader.reset()
    setPrenom(event.target.value)
    if ((nom !== '') && (event.target.value !== '')) {
      setDisableSave(false)
    }
    else {
      setDisableSave(true)
    }
  }

  function handleChangeTelephone(event) {
    setShowCam(false)
    codeReader.reset()
    setTelephone(event.target.value)
  }

  function handleChangeEmail(event) {
    setShowCam(false)
    codeReader.reset()
    setEmail(event.target.value)
  }


  function handleResult(result) {

    console.log("Reading scan")

    //Post API
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ key, code: result })
    };

    setResultScan('Vérification')

    fetch('https://vbld.alexisfles.ch/sanipasse/api/validate_pass', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        if (response.status === 200) {
          if (data.error) {
            setResultScan('Erreur')
          }
          if (data.validated) {
            //Format name with first letter uppercase
            var firstname = data.person.first_name.charAt(0).toUpperCase() + data.person.first_name.toLowerCase().slice(1)
            setPrenom(firstname)
            var lastname = data.person.last_name.charAt(0).toUpperCase() + data.person.last_name.toLowerCase().slice(1)
            setNom(lastname)
            setResultScan('Valide')
            setPassValide(true)
            if ((firstname !== '') && (lastname !== '')) {
              setDisableSave(false)
            }
          }
          else if (!data.validated) {
            setResultScan('Invalide')
          }
        }
        else {
          setResultScan('Erreur')
        }


        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        console.log(response)
      })
      .catch(error => {
        console.log({ errorMessage: error.toString() });
        console.error('There was an error!', error);
        setResultScan('Erreur')
      });

  }

  function handleError(error) {
    console.log(error)
    setResultScan('Erreur')
  }

  function handleOk() {

    setShowCam(false)
    codeReader.reset()

    if (nom.length > 0 && prenom.length > 0 && (telephone.length > 0 || email.length > 0) && passValide) {
      handleSaveAndClose()
    }
    else if (!passValide) {
      console.log("Pass sanitaire non validé.")
      setAlertMessage("Voulez-vous vraiment enregistrer ce participant ? Son pass n'a pas pu être validé.")
      setOpenAlert(true)
    }
    // else if (passValide && !(telephone.length > 0 || email.length > 0)) {
    //   console.log("Pass validé, téléphone ou email non renseigné")
    //   setAlertMessage("Voulez-vous vraiment enregistrer ce participant ? Vous n'avez renseigné ni téléphone ni adresse email.")
    //   setOpenAlert(true)
    // }
    else {
      handleSaveAndClose()
    }
  }

  function closeDialog() {
    setShowCam(false)
    codeReader.reset()
    console.log('closing Dialog')
    setPrenom('')
    setNom('')
    setEmail('')
    setTelephone('')
    setResultScan('Attente')
    setDisableSave(true)
    setOpen(false)
  }

  function handleCancel() {
    console.log('Aborting save')
    closeDialog()
  }

  function handleSaveAndClose() {
    console.log('Saving and closing')
    var myRef = firebase.database().ref('/evenements/' + branchName + '/' + currentDateId + '/exterieurs')
    var newStuff = myRef.push()
    newStuff.set({ nom, prenom, email, telephone })
    closeDialog()
  }



  let resultText, resultIcon
  if (resultScan === 'Attente') {
    resultText = 'Veuillez scanner un pass...'
    resultIcon = <QrCode2Icon />
  }
  else if (resultScan === 'Valide') {
    resultText = 'Pass valide'
    resultIcon = <CheckCircleIcon sx={{ color: '#4fe17b' }} />
  }
  else if (resultScan === 'Invalide') {
    resultText = 'Pass non valide'
    resultIcon = <ErrorIcon sx={{ color: '#ff0000' }} />
  }
  else if (resultScan === 'Erreur') {
    resultText = 'Erreur de scan, recommencez'
    resultIcon = <ErrorIcon sx={{ color: '#ff0000' }} />
  }
  else if (resultScan === 'Vérification') {
    resultText = 'Vérification du pass...'
    resultIcon = <HourglassEmptyIcon />
  }


  var camStuff = (<video id="video" />)
  if (showCam && selectedDeviceId) {
    camStuff = (
      <div onClick={() => { setShowCam(false); codeReader.reset() }}>
        <video id="video" width='100%' />
      </div>
    )
  }
  else if (showCam && !selectedDeviceId) {
    camStuff = (
      <div>
        <FiCameraOff size={200} />
        <video id="video" width='0%' />
      </div>
    )
  }
  else {
    camStuff = (
      <div onClick={() => { setShowCam(true); setResultScan('Attente') }}>
        <QrCode2Icon sx={{ fontSize: 200 }} />
        <video id="video" width='0%' />
      </div>
    )
  }


  var inputFields = (
    <div>
      <TextField id="standard-basic"
        label="Nom"
        multiline
        value={nom}
        onChange={handleChangeNom}
        fullWidth
      />
      <TextField id="standard-basic"
        label="Prénom"
        multiline
        value={prenom}
        onChange={handleChangePrenom}
        fullWidth
      />
      <TextField id="standard-basic"
        inputProps={{ inputMode: 'numeric' }}
        label="Téléphone"
        multiline
        value={telephone}
        onChange={handleChangeTelephone}
        fullWidth
      />
      <TextField id="standard-basic"
        label="Email"
        multiline
        value={email}
        onChange={handleChangeEmail}
        fullWidth
      />
    </div>
  )

  return (
    <div>
      <AlertConfirm
        open={openAlert}
        setOpen={setOpenAlert}
        handleConfirm={handleSaveAndClose}
        message={alertMessage}
      />
      <Dialog
        onClose={(event, reason) => { }}
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="Ajouter un participant"
        open={open}
        fullWidth
      >
        <DialogContent>

          <Typography variant='h6'>
            Ajouter un participant
          </Typography>
          <Box p={2} />

          <div>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >

              <Grid item xs={11}>
                <Item>
                  {camStuff}
                </Item>
              </Grid>
            </Grid>
          </div>

          <Grid container direction="row">
            <Grid item>
              {resultIcon}
            </Grid>
            <Grid item>
              <Typography>
                {resultText}
              </Typography>
            </Grid>
          </Grid>

          <Box p={1} />
          {inputFields}
          <Box p={1} />
        </DialogContent>
        <DialogActions>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button onClick={handleCancel} color="primary">
                Annuler
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleOk} color="primary" disabled={disableSave}>
                Sauvegarder
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}