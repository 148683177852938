import React, { Fragment, useContext } from 'react';
import { Typography } from '@material-ui/core';

import FirebaseContext from '../Firebase/FirebaseContext'
import { GetAdminData } from '../UtilityScripts/TreeParsing';
import LoadingDiv from '../LoadingDiv/LoadingDiv';
import DirectoryCard from '../DirectoryCard/DirectoryCard';

import firebase from '../Firebase/firebase';


export default function Administration() {

  const { trees, loadings, errors } = useContext(FirebaseContext)

  //Création de la liste si elle existe
  const data = GetAdminData(trees['treeU'], trees['treeW'])
  let annuaire
  if (!data) {
    annuaire = ('Problème')
  }
  else {
    annuaire = (
      data.map(sportif => <DirectoryCard key={sportif['uid']}
        sportif={sportif}
        boutons="admin"
        handleClickAccept={handleClickAccept}
      // handleClickBan={handleClickBan}
      />)
    )
  }


  //Chargement en cours ou utilisateur non autorisé ou erreur Firebase
  if (loadings['loadingTreeU'] || loadings['loadingTreeW']) {
    return <LoadingDiv />
  }
  else if (errors['errorTreeU'] || errors['errorTreeW']) {
    return (
      <Fragment>
        <h3>Erreur</h3>
        <Typography>
          Une erreur est survenue : {errors['errorTreeU']}, {errors['errorTreeW']}
        </Typography>
      </Fragment>
    )
  }


  function handleClickAccept(sportif, checkedWhiteList, checkedMiniAdmin, checkedVacances) {
    if (!checkedWhiteList) {
      handleBan(sportif)
    }
    else {
      console.log("Authorizing user : ")
      console.log(sportif['uid'])
      console.log("vacances : ", checkedVacances)
      var myRef = firebase.database().ref('/whiteList/' + sportif['uid'])
      myRef.set({ admin: false, coach: false, miniAdmin: checkedMiniAdmin, vacances: checkedVacances })
      myRef = firebase.database().ref('/users/' + sportif['uid'] + '/readOnly/registred')
      myRef.set(true)
    }
  }

  function handleBan(sportif) {
    console.log("Removing user from whiteList (provided that he is in it)")
    console.log(sportif['uid'])
    var myRef = firebase.database().ref('/whiteList/' + sportif['uid'])
    myRef.remove()
    console.log("Adding user to blackList for future reference")
    myRef = firebase.database().ref('/blackList/' + sportif['uid'])
    myRef.set({ banned: true })
  }


  return (
    <Fragment>
      {annuaire}
    </Fragment>
  )
}