import React from 'react'
import Settings from '../Settings/Settings';
import Annuaire from '../Annuaire/Annuaire';
import Gymnases from '../Gymnases/Gymnases';
import Administration from '../Administration/Administration';
import Resultats from '../Resultats/Resultats'
import Pots from '../Pots/Pots'
import Entrainements from '../Entrainements/Entrainements';
import Matchs from '../Matchs/Matchs';
import SortMenu from '../SortMenu/SortMenu';
import PseudoMenu from '../SortMenu/PseudoMenu'
import News from '../Nouvelles/News';
import Beach from '../Beach/Beach';
import Equipe1 from '../Equipe1/Equipe1';
// import Equipe2 from '../Equipe2/Equipe2';
import { mdiWeatherPartlyCloudy } from '@mdi/js';
import { mdiWeatherLightningRainy } from '@mdi/js';
import { mdiWeatherLightning } from '@mdi/js';
import { mdiWeatherRainy } from '@mdi/js';
import { mdiWeatherPouring } from '@mdi/js';
import { mdiWeatherSnowy } from '@mdi/js';
import { mdiWeatherSnowyHeavy } from '@mdi/js';
import { mdiWeatherSnowyRainy } from '@mdi/js';
import { mdiWeatherWindyVariant } from '@mdi/js';
import { mdiWeatherFog } from '@mdi/js';
import { mdiWeatherSunny } from '@mdi/js';
import { mdiWeatherCloudy } from '@mdi/js';
import { Icon } from '@mdi/react';



const correspondanceMain = {
  "entrainements": ["Entraînements", <Entrainements />, <SortMenu />, <PseudoMenu />],
  "autres matchs": ["Autres matchs", <Matchs />, <SortMenu />, <PseudoMenu />],
  "news": ["Accueil", <News />, "", ""],
  "pots": ["Pots", <Pots />, <SortMenu />, <PseudoMenu />],
  "gymnases": ["Gymnases", <Gymnases />, "", ""],
  "annuaire": ["Annuaire", <Annuaire />, "", <PseudoMenu />],
  "settings": ["Paramètres", <Settings />, "", ""],
  "administration": ["Administration", <Administration />, "", ""],
  "resultats1": ["Informations poule B", <Resultats key='foo' equipe='equipe1' />, "", ""],
  // "resultats2": ["Informations poule C", <Resultats key='bar' equipe='equipe2' />, "", ""],
  "beach": ["Beach", <Beach />, <SortMenu />, <PseudoMenu />],
  "equipe1": ["Poule B", <Equipe1 />, <SortMenu />, <PseudoMenu />],
  // "equipe2": ["Poule C", <Equipe2 />, <SortMenu />, <PseudoMenu />]
}


const tabs = [
  'Accueil',
  'Entraînements',
  'Poule B',
  // 'Poule C',
  // 'Résultats',
  'Autres matchs',
  'Beach',
  'Pots',
  'Annuaire',
  'Gymnases',
];

const correspondanceSettings = {
  'Accueil': 'news',
  'Entraînements': 'entrainements',
  'Autres matchs': 'autres matchs',
  'Résultats': 'resultats',
  'Beach': 'beach',
  'Pots': 'pots',
  'Annuaire': 'annuaire',
  'Gymnases': 'gymnases',
  'news': 'Accueil',
  'entrainements': 'Entraînements',
  'autres matchs': 'Autres matchs',
  'Resultats': 'Résultats',
  'beach': 'Beach',
  'pots': 'Pots',
  'annuaire': 'Annuaire',
  'gymnases': 'Gymnases',
  'equipe1': 'Poule B',
  // 'equipe2': 'Poule C',
  'Poule B': 'equipe1',
  // 'Poule C': 'equipe2'
}

function codeToIcon(code, size, color) {
  let path
  // Look for code...
  if (code < 230) {
    path = mdiWeatherLightningRainy
  }
  else if ((231 <= code) && (code <= 299)) {
    path = mdiWeatherLightning
  }
  else if ((300 <= code) && (code <= 499)) {
    path = mdiWeatherRainy
  }
  else if ((500 <= code) && (code <= 599)) {
    path = mdiWeatherPouring
  }
  else if ((600 <= code) && (code <= 601)) {
    path = mdiWeatherSnowy
  }
  else if (602 === code) {
    path = mdiWeatherSnowyHeavy
  }
  else if ((603 <= code) && (code <= 610)) {
    path = mdiWeatherSnowyRainy
  }
  else if ((611 <= code) && (code <= 620)) {
    path = mdiWeatherWindyVariant
  }
  else if ((621 <= code) && (code <= 699)) {
    path = mdiWeatherSnowyHeavy
  }
  else if ((700 <= code) && (code <= 799)) {
    path = mdiWeatherFog
  }
  else if (code === 800) {
    path = mdiWeatherSunny
  }
  else if ((801 <= code) && (code <= 802)) {
    path = mdiWeatherPartlyCloudy
  }
  else if ((803 <= code) && (code <= 899)) {
    path = mdiWeatherCloudy
  }
  else {
    path = mdiWeatherPouring
  }

  // If code found
  return <Icon path={path} size={size} color={color} />
}

export { tabs, correspondanceSettings, correspondanceMain, codeToIcon }