import React from "react"

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';
import 'date-fns';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles';

import AlertConfirm from './AlertConfirm.jsx';


const useStyles = makeStyles(theme => ({
  margins: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  inputField: {
    // margin: -theme.spacing(1),
    // padding: -theme.spacing(1),
  },
}));

export default function DeleteExterieur(props) {

  const classes = useStyles();
  const { sportif, handleDelete } = props;

  const nom = sportif['nom']
  const prenom = sportif['prenom']
  const pseudo = sportif['pseudo']


  //State for the alert dialog
  const [alertMessage, setAlertMessage] = React.useState('')

  //State to open an alert dialog when deleting someone
  const [openAlert, setOpenAlert] = React.useState(false)

  function handleOk() {
    //Show alert message
    setAlertMessage('Attention ! Cette action est irréversible !')
    setOpenAlert(true)
  }


  function handleCancel() {
    console.log('Aborting save')
    handleDelete(sportif, false)
  }

  function handleDeleteAndClose() {
    console.log('Deleting and closing')
    handleDelete(sportif, true)
  }





  var inputFields = (
    <div>
      <TextField id="standard-basic"
        label="Nom"
        multiline
        value={nom}
        fullWidth
        disabled={true}
      />
      <TextField id="standard-basic"
        label="Prénom"
        multiline
        value={prenom}
        disabled={true}
        fullWidth
      />
      <TextField id="standard-basic"
        label="Pseudo"
        multiline
        value={pseudo}
        disabled={true}
        fullWidth
      />
    </div>
  )

  return (
    <div className={classes.margins}>
      <AlertConfirm
        open={openAlert}
        setOpen={setOpenAlert}
        handleConfirm={handleDeleteAndClose}
        message={alertMessage}
      />

      <Typography variant='h6'>
        Voulez-vous supprimer cette personne du listing ?
      </Typography>
      <Box p={1.5} />
      {inputFields}
      <Box p={1} />

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button onClick={handleCancel} color="primary">
            Annuler
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleOk} color="primary">
            Supprimer
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}