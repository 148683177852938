import React, { Fragment } from 'react'
import PresenceCivilite from './PresenceCivilite'
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import { getBackgroundColor2 } from './Colors';
import HistoryIcon from '@mui/icons-material/History';



function generateOptionsDanjoutin(options, sportif, setPresence) {
  var res = []
  let foo
  let inset
  for (const opt of options) {
    //Add volleyball icon to the current option
    if (opt === sportif['present']) {
      foo = (<ListItemIcon><SportsVolleyballIcon /></ListItemIcon>)
      inset = false
    }
    //Add history icon to option set by real user
    else if (sportif['historiquePresence'] && opt === sportif['historiquePresence']) {
      foo = (<ListItemIcon><HistoryIcon /></ListItemIcon>)
      inset = false
    }
    else {
      foo = ''
      inset = true
    }
    //Bipbip ou coyotte ?
    var opt2 = PresenceCivilite(sportif, opt)

    //Normalement ce tiroir ne s'ouvre que si on a le droit de modifier des présence
    const fun = () => setPresence(opt)

    //Création de la liste
    res.push(
      <Fragment key={opt}>
        <ListItem button onClick={fun} style={{ backgroundColor: getBackgroundColor2(opt) }}>
          {foo}
          <ListItemText inset={inset} primary={opt2} />
        </ ListItem>
        <Divider />
      </Fragment >
    )
  }
  return res
}


export default function generateOptions(options, sportif, setPresence) {
  if (sportif['present'] && (sportif['present'] === 'Extérieur(e)' || sportif['present'] === 'Invité(e)')) {
    return (<></>)
  }
  else {
    return generateOptionsDanjoutin(options, sportif, setPresence)
  }
}