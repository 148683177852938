import React, { Fragment, useContext } from 'react';
import Box from '@material-ui/core/Box';
import GymnaseCard from './GymnaseCard';
// import { ListGymnases } from '../UtilityScripts/createStuff';

import LoadingDiv from '../LoadingDiv/LoadingDiv'
import Error from '../Error/Error'

import FirebaseContext from '../Firebase/FirebaseContext'


export default function Gymnases() {




  const { trees, loadings, errors } = useContext(FirebaseContext)

  //Pour que ça soit lisible
  const { treeE } = trees;
  const { loadingE } = loadings;
  const { errorE } = errors;

  if (errorE) {
    return <Error />
  }

  if (loadingE) {
    return <LoadingDiv />
  }


  const ListGymnases = treeE['gymnases'];

  const annuaire = (
    Object.keys(ListGymnases).map((key, i) =>
      <GymnaseCard key={i}
        data={ListGymnases[key]}
      />))


  return (
    <Fragment>
      <Box p={1.5} />
      {annuaire}
    </Fragment>
  )
}