import React, { useContext } from 'react';
import MaterialUIPickers from '../DatePicker/datePicker';
import Grid from '@material-ui/core/Grid';

import FirebaseContext from '../Firebase/FirebaseContext'
import { extractDates, extractPresenceEntrainements } from '../UtilityScripts/TreeParsing'
import { isMiniAdmin } from '../UtilityScripts/FindStuff'


import ListeSportifs from '../ListeSportifs/ListeSportifs'
import LocationAndTime from '../LocationAndTime/LocationAndTime'
import LoadingDiv from '../LoadingDiv/LoadingDiv'
import AffichageTotaux from '../AffichageTotaux/AffichageTotaux'
import { makeStyles } from '@material-ui/core/styles';
import { multipleEvents, findLocationAndTime } from '../UtilityScripts/TreeParsing'
import AlertInfo from '../AlertInfo/AlertInfo'
import Box from '@material-ui/core/Box'
import Error from '../Error/Error'
import AddButtonExterieur from '../Administration/AddButtonExterieur'
import { isAdmin } from '../UtilityScripts/FindStuff'
import AddButtonInvite from '../Administration/AddButtonInvite'



const useStyles = makeStyles(theme => ({
  margins: {
    paddingTop: theme.spacing(1),
    margin: 0,
  },
}));


function isUserAdminCovid(user, loadingW, treeW) {
  if (loadingW || !treeW) {
    return false
  }
  else {
    return (treeW[user['uid']]['adminCovid'])
  }
}

export default function Entrainements() {
  const classes = useStyles();

  //Context de firebase
  const { trees, loadings, errors, user } = useContext(FirebaseContext)

  //Pour que ça soit lisible
  const { treeE, treeU, treeW } = trees;
  const { loadingE, loadingU, loadingW } = loadings;
  const { errorE, errorW } = errors;

  //Listes des dates des matchs (à donner au calendrier ensuite)
  var { datesAndMore, nextEvent } = extractDates(trees['treeE'], loadings['loadingE'], "entrainements")

  //Date du prochain événement
  var [date, dateId] = [nextEvent['date'], nextEvent['dateId']]

  //State passé au calendrier qui permettra de faire remonter l'info
  //si un changement de date a eu lieu
  const [currentDateId, setCurrentDateId] = React.useState(dateId)

  //Mise à jour de l'affichage
  React.useEffect(() => {
    setCurrentDateId(dateId);
  }, [dateId]);

  if (errorE) {
    return <Error />
  }

  //Is current user admin ?
  const admin = isAdmin(trees['treeW'], loadings['loadingW'], errors['errorW'], user['uid'])

  //Is user a covid admin ?
  var adminCovid = isUserAdminCovid(user, loadings['loadingW'], trees['treeW'])

  //Infobulles avec le nombre de présents
  const { totaux } = extractPresenceEntrainements(treeE, loadingE, treeW, loadingW, treeU, loadingU, "entrainements", currentDateId)

  //On affiche pas le nombre de statuts covid vérifiés si on est pas admin covid:
  if (!adminCovid) {
    totaux['Covid'] = 0
  }

  // Les utilisateurs autorisés peuvent ajouter un exterieur 
  // - covidAdmin : si la fenêtre de temps est la bonne
  // - admin : tout le temps
  let addExterieur, addInvite

  if (adminCovid) {
    //Get date of current event :
    const { date: dateT } = findLocationAndTime(trees['treeE'], loadings['loadingE'], 'entrainements', currentDateId)
    //Get current date :
    const now = new Date()
    //if now is between date-1h and date+7h, we display the button
    if ((dateT && now.getTime() > (dateT.getTime() - 3600 * 1000) && now.getTime() < (dateT.getTime() + 7 * 3600 * 1000)) || admin) {
      addExterieur = <AddButtonExterieur
        branchName='entrainements'
        currentDateId={currentDateId}
      />
    }
  }

  if (isMiniAdmin(treeW, loadingW, errorW, user['uid'])) {
    addInvite = <AddButtonInvite
      branchName='entrainements'
      currentDateId={currentDateId}
      userId={user['uid']}
    />
  }


  //S'il y a un autre événement en même temps que l'entraînement, on affiche un panneau
  const { mult, events } = multipleEvents(treeE, loadingE, errorE, "entrainements", currentDateId)
  let attention
  if (!mult) {
    attention = <Box p={3} />
  }
  else {
    attention = <AlertInfo events={events} />
  }


  if (loadings['loadingE']) {
    return <LoadingDiv />
  }
  else {
    return (
      <div className={classes.margins}>
        <AffichageTotaux totaux={totaux} />
        <MaterialUIPickers
          currentDate={date}
          currentDateId={currentDateId}
          setCurrentDateId={setCurrentDateId}
          datesAndMore={datesAndMore}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item>
            <LocationAndTime
              currentDateId={currentDateId}
              trainingOrMatch="entrainements"
            />
          </Grid>
          <Grid item xs={1}>
            {attention}
          </Grid>
        </Grid>
        <ListeSportifs
          currentDateId={currentDateId}
          trainingOrMatch="entrainements"
        />
        {addInvite}
        {addExterieur}
      </div >
    )
  }
}
